<script setup lang="ts">
import { breakpointsTailwind, useScrollLock } from '@vueuse/core';
import { gsap } from 'gsap';

const {
    backdrop = 'dark',
    animateDrawer = false,

} = defineProps<{
    backdrop?: 'dark' | 'light';
    animateDrawer?: boolean;
}>();

const emit = defineEmits(['closed']);

const elDialog = useTemplateRef('elDialog');
const elContent = useTemplateRef('elContent');
const elBackdrop = useTemplateRef('elBackdrop');
const windowIsLocked = useScrollLock(window);
const bodyIsLocked = useScrollLock(window);
const breakpoints = useBreakpoints(breakpointsTailwind);
const { dialogClasses } = useDialogPolyfill(elDialog);
onMounted(() => { windowIsLocked.value = true; bodyIsLocked.value = true; });
onUnmounted(() => { windowIsLocked.value = false; bodyIsLocked.value = false; });

const backdropClasses = computed(() => [
    backdrop === 'dark' ? 'bg-brand-b/90' : 'bg-shade-3/90',
]);

/**
 * Control Open/Close State
 * --------------------------------------------------------------------------------------------------
 */

/**
 *
 */
async function open() {
    elDialog.value?.showModal();
    animateOpen();
}

/**
 *
 */
function close(e?: Event) {
    e?.preventDefault();

    animateClose(() => {
        elDialog.value?.close();
        emit('closed');
    });
}

/**
 *
 */
onMounted(() => elDialog.value?.addEventListener('cancel', (e) => {
    e.preventDefault();
    close();
}));

/**
 * Animations
 * --------------------------------------------------------------------------------------------------
 */

const yTransform = computed(() => {
    if (animateDrawer && breakpoints.smaller('md').value) {
        return '100%';
    }
    return 200;
});

/**
 *
 */
function animateOpen() {
    const tl = gsap.timeline();
    const slidingElements = elContent.value?.querySelectorAll('[data-transition-slide]') ?? [];
    const fadingElements = elContent.value?.querySelectorAll('[data-transition-fade]') ?? [];

    if (elBackdrop.value) {
        tl.fromTo(elBackdrop.value, { opacity: 0, backdropFilter: 'blur(0px)' }, { opacity: 1, backdropFilter: 'blur(10px)', duration: 0.3 });
    }
    if (slidingElements.length) {
        tl.set(slidingElements, { y: yTransform.value, opacity: 0 }, 0);
        tl.fromTo(slidingElements, { opacity: 1, y: yTransform.value }, { opacity: 1, y: 0, duration: 0.3, ease: 'power3.out', immediateRender: false }, 0);
    }
    if (fadingElements.length) {
        tl.fromTo(fadingElements, { opacity: 0 }, { opacity: 1, duration: 0.3 }, '<25%');
    }
};

/**
 *
 */
function animateClose(closeCallback: () => void) {
    const tl = gsap.timeline({ onComplete: closeCallback });
    const slidingElements = elContent.value?.querySelectorAll('[data-transition-slide]') ?? [];
    const fadingElements = elContent.value?.querySelectorAll('[data-transition-fade]') ?? [];

    if (fadingElements.length) {
        tl.to(fadingElements, { opacity: 0, duration: 0.3 }, 0);
    }
    if (slidingElements.length) {
        tl.to(slidingElements, { opacity: 0, y: yTransform.value, duration: 0.3, ease: 'power1.in' }, 0);
    }
    if (elBackdrop.value) {
        tl.to(elBackdrop.value, { opacity: 0, backdropFilter: 'blur(0px)', duration: 0.3 }, 0.2);
    }
};

/**
 *
 */
function animateNextItem(switchCallback: () => void) {
    const t1 = gsap.timeline();
    const nextElements = elContent.value?.querySelectorAll('[data-transition-next]') ?? [];

    if (nextElements.length) {
        t1.fromTo(nextElements, { opacity: 1, x: 0 }, { opacity: 0, x: -200, duration: 0.3, ease: 'power3.in', onComplete: switchCallback });
        t1.fromTo(nextElements, { opacity: 0, x: 200 }, { opacity: 1, x: 0, duration: 0.3, ease: 'power2.out', immediateRender: false });
    }
    else {
        switchCallback();
    }
};

/**
 *
 */
defineExpose({
    open,
    close,
    animateNextItem,
});
</script>

<template>
    <dialog
        ref="elDialog"
        class="
            base-dialog
            pointer-events-auto
            m-0
            h-dvh
            max-h-none
            w-screen
            max-w-none
            bg-transparent
            p-0
            text-brand-b
        "
        :class="dialogClasses"
    >
        <div
            ref="elContent"
            class="
                grid
                h-full
                grid-rows-[auto]
            "
        >
            <slot />
        </div>

        <div
            ref="elBackdrop"
            class="
                duration-50
                fixed
                inset-0
                -z-10
                backdrop-blur
                transition-colors
            "
            :class="backdropClasses"
        />
    </dialog>
</template>

<style scoped>
    .base-dialog {
        scrollbar-width: thin;
        scrollbar-gutter: stable;
    }
</style>
